import axios from "axios";
import showErrorSnackbar from "../components/instance_components/Snackbar";
import { haveCommonElements } from ".";

export const fetchExchangeData = async (apiUrl, headers) => {
  try {
    const response = await axios.get(apiUrl, { headers });
    const data = response.data.liquidityData;
    return data;
  } catch (e) {
    console.log("error happened: ", e.message);
  }
};

export const downloadDataApi = async (
  selectedSubstrats,
  allStrategies,
  selectedStrats,
  isLanding,
  strats,
  selectedFinyears,
  headers,
  format
) => {
  let subStrs = selectedSubstrats;
  allStrategies.forEach((strategyObj) => {
    if (selectedStrats.includes(strategyObj.name)) {
      if (!haveCommonElements(selectedSubstrats, strategyObj.subStrategy))
        subStrs.push(...strategyObj.subStrategy);
      if (strategyObj.subStrategy.length === 0 && !subStrs.includes("_"))
        subStrs.push("_");
    }
  });
  try {
    const response = await axios.post(
      "/api/live/downloadData",
      {
        strategies: isLanding ? strats : selectedStrats,
        subStrategies: subStrs,
        // years: isLanding ? ["2024"] : selectedFinyears,
        years: isLanding ? ["2025"] : selectedFinyears,

        format: format,
      },
      { headers }
    );

    return { response, err: null };
  } catch (err) {
    return { response: null, err };
  }
};

export const downloadDataBtApi = async (
  isLanding,
  strats,
  selectedStrats,
  selectedFinyears,
  headers,
  format,
  fileName
) => {
  try {
    const response = await axios.post(
      "/api/bt/downloadData",
      {
        strategies: isLanding ? strats : selectedStrats,
        // years: isLanding ? ["2024"] : selectedFinyears,
        years: isLanding ? ["2025"] : selectedFinyears,

        format: format,
      },
      { headers }
    );
    const blob = new Blob([response.data], {
      type: "application/json",
    });

    // Create a link element and trigger a download
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${fileName}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    return { response: response, err: null };
  } catch (err) {
    return { response: null, err: err };
  }
};
