import React, { useEffect, useRef, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import axios from "axios";
import { finyears, formats } from "../data";
import { CSVLink } from "react-csv";
import CalendarComp from "../components/Calendar";
import "react-calendar/dist/Calendar.css";
import DataTable from "../components/DataTable";
import {
  checkYearsContinuity,
  convertDate,
  convertToSavedFormat,
  downloadLocally,
  haveCommonElements,
} from "../utils";
import Block from "../components/Block";

import DropdownHelper from "../components/Helper";

import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoaderComp from "../components/LoaderComp";
import LineChart from "../components/Linechart";
import FilterDropdown from "../components/FilterDropdown";
import showErrorSnackbar from "../components/instance_components/Snackbar";
import PieChart from "../components/PieChart";
import GroupedTable from "../components/GroupedTable";

import ToggleSwitch from "../components/ToggleSwitch";

import HoverDropdown from "../components/HoverDropdown";
import SessionExpiredDialog from "../components/SessionExpiredDialog";
import Card from "../components/Card";
import MultipleLineChart from "../components/MultipleLineChart";
import { downloadDataApi } from "../utils/api";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

//let pageSize = 15;
function Dashboard_live() {
  //const [currentPage, setCurrentPage] = useState(1);

  // const { token } = useAuthToken();
  // const dispatch = useDispatch()
  const { token, tokenExpiryTime } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  const [subStrategies, setSubStrategies] = useState([]);

  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [maxDrawdown, setMaxDrawdown] = useState(0);
  const [cdd, setCdd] = useState(0);
  const [mdd_date_st, setMdd_date_st] = useState("");
  const [mdd_date_end, setMdd_date_end] = useState("");
  const [cdd_days, setCdd_days] = useState("");
  const [mtv, setMtv] = useState();
  const [isLanding, setIsLanding] = useState(true);
  const [selectedOption, setSelectedOption] = useState("live");
  const [landingData, setLandingData] = useState([]);
  const [selectedStrats, setSelectedStrats] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedFinyears, setSelectedFinyears] = useState([]);
  const [date, setDate] = useState(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
  );

  const [tableData, setTableData] = useState([]);
  const [strategyWiseData, setStrategyWiseData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [date_2, setDate_2] = useState(new Date());
  const [val1, setVal1] = useState();
  const [val2, setVal2] = useState();
  const [val3, setVal3] = useState();
  const [allClients, setAllClients] = useState([]);
  const [profitPieData, setProfitPieData] = useState([]);
  const [lossPieData, setLossPieData] = useState([]);
  const [posDays, setPosDays] = useState();
  const [negDays, setNegDays] = useState();
  const [annualisedPnl, setAnnualisedPnl] = useState();
  const [selectedFormat, setSelectedFormat] = useState("Year wise");
  const [clients, setClients] = useState([]);
  const [strats, setStrats] = useState([]);
  const [allStrategies, setAllStrategies] = useState([]);
  const [selectedSubstrats, setSelectedSubstrats] = useState([]);
  const [years, setYears] = useState(finyears);
  const [hover, setHover] = useState(false);
  const [substrategyChartData, setSubStrategyChartData] = useState([]);
  const [flag, setFlag] = useState();
  const [yearStrategyWiseMap, setYearStrategyWiseMap] = useState({});
  const backupOverallData = useRef(null);

  const headers = {
    Authorization: `Bearer ${
      token === null ? localStorage.getItem("token") : token
    }`, // Use 'Bearer' followed by a space before the token
    "Content-Type": "application/json", // Set the content type if needed
  };
  useEffect(() => {
    setTableData([]);
    setFlag(false);
  }, [selectedFormat, selectedStrats, selectedFinyears]);

  useEffect(() => {
    if (selectedOption === "backtesting") navigate("/bt_data");
  }, [selectedOption]);

  const fetchLandingData = async () => {
    setLoading(true);
    const newValues = ["Select All", "Clear All"];
    // console.log(isLoading);

    let responseData;
    let pieProfitData = [];
    let strategies = [];
    let clients = {};
    let pieLossData = [];
    let rowsArr = [];
    let overallProfit = 0;
    let overallLoss = 0;
    let subStrs = [];
    let allStrategies = [];
    try {
      responseData = await axios.get("/api/live/getStrategyData", { headers });

      console.log("strategyData", responseData);

      strategies.push(
        ...responseData.data.strategyData.map((item) => item.name)
      );

      allStrategies.push(...responseData.data.strategyData);

      responseData.data.strategyData.forEach((item) => {
        const client = item.client;
        const strategy = item.name;
        if (item.subStrategy.length) subStrs.push(...item.subStrategy);
        if (clients.hasOwnProperty(client)) {
          clients[client].push(strategy);
        } else {
          clients[client] = [strategy];
        }
      });

      // console.log("clients ", clients)

      const body = {
        strats: strategies,
      };

      responseData = await axios.post("/api/live/getLandingData", body, {
        headers,
      });
      console.log("response", responseData);

      if (responseData.data.strategywiseData.length === 0) {
        throw new Error("No data");
      }
      // console.log("responseData", responseData);
      responseData.data.strategywiseData.forEach((item) => {
        const category = `${item.strategy}`;
        // if (item.netPnl === "N/A") item.netPnl = 0;
        // overallNetPnl += item.netPnl;
        item.netpnl = parseInt(item.netpnl);
        const obj = {
          category,
          netpnl: item.netpnl,
        };
        // pieData.push(obj);
        if (obj.netpnl !== "N/A" && obj.netpnl >= 0) {
          pieProfitData.push(obj);
          overallProfit += obj.netpnl;
        } else if (obj.netpnl !== "N/A" && obj.netpnl < 0) {
          obj.netpnl = -obj.netpnl;
          pieLossData.push(obj);
          overallLoss += obj.netpnl;
        }
        // item.netPnl = parseFloat((item.netPnl / item.uniqueDates) * 252);
      });
    } catch (err) {
      console.log("error happened", err);
      setLoading(false);

      showErrorSnackbar(err.message);
      // if (err.response.status === 401 || err.response.status === 403)
      //   navigate("/");
      return;
    }

    responseData.data.strategywiseData.map((d) => rowsArr.push(Object.keys(d)));
    pieProfitData.length > 0 &&
      pieProfitData.forEach((item) => {
        item.netpnl = (item.netpnl / overallProfit) * 100;
      });
    pieLossData.length > 0 &&
      pieLossData.forEach((item) => {
        item.netpnl = (item.netpnl / overallLoss) * 100;
      });
    let chartFormattedData = [];
    responseData.data.overallData.chartData.forEach((item) => {
      const chartObj = {
        date: convertDate(item.date),
        netPnl: item.netPnl,
      };
      chartFormattedData.push(chartObj);
    });

    if (strategies.indexOf("Select All") === -1) {
      setStrats([...newValues, ...strategies]);
      setSelectedStrats(strategies);
    }

    const clientsArr = Object.keys(clients);
    if (clientsArr.indexOf("Select All") === -1) {
      setClients([...newValues, ...clientsArr]);
      setSelectedClients(clientsArr);
    }

    setAllStrategies(allStrategies);
    setSelectedSubstrats(subStrs);
    setAllClients(clients);
    setProfitPieData(pieProfitData);
    setLossPieData(pieLossData);
    setVal1(responseData.data.overallData.overallNetPnl);
    setVal2(responseData.data.overallData.overallThrPnl);
    setVal3(responseData.data.overallData.overallTv);
    setMaxDrawdown(responseData.data.overallData.overallMdd);
    setCdd(responseData.data.overallData.overallCdd);
    setMdd_date_st(responseData.data.overallData.mddStartDate);
    setMdd_date_end(responseData.data.overallData.mddEndDate);
    setPosDays(responseData.data.overallData.overallPositiveDays);
    setNegDays(responseData.data.overallData.overallNegativeDays);
    setAnnualisedPnl(responseData.data.overallData.overallAnnualisedPnl);
    setCdd_days(responseData.data.overallData.overallCddDays)
    setColumns(rowsArr[0]);
    setChartData(chartFormattedData);
    setLandingData(responseData.data.strategywiseData);
    if (!backupOverallData.current) {
      backupOverallData.current = responseData.data.overallData;
    }

    setLoading(false);
    // console.log("here");
  };

  const fetchYearStrategySubstrategyWiseData = async () => {
    let subStrs = selectedSubstrats;
    // console.log("substr", subStrs);
    // console.log("allStrategy", allStrategies);
    allStrategies.forEach((strategyObj) => {
      if (selectedStrats.includes(strategyObj.name)) {
        if (!haveCommonElements(selectedSubstrats, strategyObj.subStrategy))
          subStrs.push(...strategyObj.subStrategy);
        if (strategyObj.subStrategy.length === 0 && !subStrs.includes("_"))
          subStrs.push("_");
      }
    });
    // console.log("substr", subStrs);
    let years = [];
    if (selectedFormat === "Custom Date") {
      let month1 = parseInt(date.getMonth()) + 1;
      let year1 =
        month1 > 3
          ? parseInt(date.getFullYear())
          : parseInt(date.getFullYear()) - 1;
      let month2 = parseInt(date_2.getMonth()) + 1;
      let year2 =
        month2 > 3
          ? parseInt(date_2.getFullYear())
          : parseInt(date_2.getFullYear()) - 1;
      while (year1 <= year2) {
        years.push(year1.toString());
        year1++;
      }
    }

    const body = {
      years: selectedFormat === "Custom Date" ? years : selectedFinyears,
      strategies: selectedStrats,
      subStrategies: subStrs,
      date1:
        selectedFormat === "Custom Date" ? convertToSavedFormat(date) : null,
      date2:
        selectedFormat === "Custom Date" ? convertToSavedFormat(date_2) : null,
    };
    let rowsArr = [];
    //if (client !== "all") body.client = client;
    try {
      let responseData = await axios.post(
        "/api/live/getYearStrategySubstrategyData",
        body,
        { headers }
      );
      console.log("response", responseData);

      let substrategyFinalData = [];
      let len = 0;
      let uniqueDates = [];

      for (let key in responseData.data.substrategyChartData) {
        let data = responseData.data.substrategyChartData[key];

        if (data.length > len) {
          uniqueDates = [...new Set(data.map((item) => item.date))];
          len = data.length;
        }
      }

      uniqueDates = Array.from(uniqueDates);
      console.log("uniqueDates", uniqueDates);

      for (let i = 0; i < uniqueDates.length; i++) {
        let date = uniqueDates[i];
        for (let key in responseData.data.substrategyChartData) {
          let index = 0;
          let lastIndex = key.lastIndexOf("_");
          let name = key.substring(0, lastIndex);
          const data = responseData.data.substrategyChartData[key];

          data.sort((a, b) => a.date - b.date);

          const existingDateIndex = data.findIndex((obj) => obj.date === date);

          if (existingDateIndex === -1) {
            // Date doesn't exist, find the correct position to insert it
            while (index < data.length && data[index].date < date) {
              index++;
            }

            // Insert the date at the correct position with netpnl value from previous element
            const netpnl = index > 0 ? data[index - 1].netPnl : 0; // Assuming netpnl starts from 0 if no previous element
            data.splice(index, 0, { date: date, netPnl: netpnl });
          }

          let obj = {
            name: name,
            data: data,
          };

          if (i === uniqueDates.length - 1) {
            substrategyFinalData.push(obj);
          }
        }
      }

      setSubStrategyChartData(substrategyFinalData);
      setTableData(responseData.data.tableData);
      responseData.data.tableData.map((d) => rowsArr.push(Object.keys(d)));
      setColumns(rowsArr[0]);
    } catch (err) {
      // console.log("fetchYearStrategySubstrategy")
      console.log("error happened", err);
      setLoading(false);

      showErrorSnackbar(err.message);
      // if (err.response.status === 401 || err.response.status === 403)
      //   navigate("/");
      return;
    }
  };
  const fetchYearStrategyWiseData = async () => {
    let responseData;
    let yearStrategyMap = {};
    let years = [];
    if (selectedFormat === "Custom Date") {
      let month1 = parseInt(date.getMonth()) + 1;
      let year1 =
        month1 > 3
          ? parseInt(date.getFullYear())
          : parseInt(date.getFullYear()) - 1;
      let month2 = parseInt(date_2.getMonth()) + 1;
      let year2 =
        month2 > 3
          ? parseInt(date_2.getFullYear())
          : parseInt(date_2.getFullYear()) - 1;
      while (year1 <= year2) {
        years.push(year1.toString());
        year1++;
      }
    }

    const body = {
      years: selectedFormat === "Custom Date" ? years : selectedFinyears,
      strategies: selectedStrats,
      date1:
        selectedFormat === "Custom Date" ? convertToSavedFormat(date) : null,
      date2:
        selectedFormat === "Custom Date" ? convertToSavedFormat(date_2) : null,
    };
    // console.log("body", body);
    try {
      responseData = await axios.post(
        "/api/live/getYearStrategyWiseData",

        body,

        {
          headers,
        }
      );

      // console.log(responseData);
      console.log("yearStrategyWiseData", responseData);
      responseData.data.yearStrategyWiseData.forEach((item) => {
        yearStrategyMap[`${item.strategy} ${item.year}`] = {
          netpnl: item.netpnl,
          thrpnl: item.thrpnl,
          tv: item.tv,
          mdd: item.mdd,
          cdd: item.cdd,
          worstPnl: item.worstPnl,
          bestPnl: item.bestPnl,
        };
      });
    } catch (err) {
      // console.log("yearStrategyWise")
      setLoading(false);
      showErrorSnackbar(err.message);
      return;
    }
    // console.log("yearRegionMap", yearRegionMap);
    // console.log("yearStrategy", yearStrategyMap)
    setYearStrategyWiseMap(yearStrategyMap);
  };

  //Function to fetch the overallData
  const fetchOverallData = async () => {
    let years = [];
    let subStrs = selectedSubstrats;
    allStrategies.forEach((strategyObj) => {
      if (selectedStrats.includes(strategyObj.name)) {
        if (!haveCommonElements(selectedSubstrats, strategyObj.subStrategy))
          subStrs.push(...strategyObj.subStrategy);
        if (strategyObj.subStrategy.length === 0 && !subStrs.includes("_"))
          subStrs.push("_");
      }
    });
    if (selectedFormat === "Custom Date") {
      let month1 = parseInt(date.getMonth()) + 1;
      let year1 =
        month1 > 3
          ? parseInt(date.getFullYear())
          : parseInt(date.getFullYear()) - 1;
      let month2 = parseInt(date_2.getMonth()) + 1;
      let year2 =
        month2 > 3
          ? parseInt(date_2.getFullYear())
          : parseInt(date_2.getFullYear()) - 1;
      while (year1 <= year2) {
        years.push(year1.toString());
        year1++;
      }
    }
    const body = {
      years: selectedFormat === "Custom Date" ? years : selectedFinyears,
      strategies: selectedStrats,
      subStrategies: subStrs,
      date1:
        selectedFormat === "Custom Date" ? convertToSavedFormat(date) : null,
      date2:
        selectedFormat === "Custom Date" ? convertToSavedFormat(date_2) : null,
    };
    try {
      let responseData = await axios.post("/api/live/getOverallData", body, {
        headers,
      });
      let chartFormattedData = [];
      responseData.data.overallData.chartData.length &&
        responseData.data.overallData.chartData.forEach((item) => {
          const chartObj = {
            date: convertDate(item.date),
            netPnl: item.netPnl,
          };
          chartFormattedData.push(chartObj);
        });
      setVal1(responseData.data.overallData.overallNetPnl);
      setVal2(responseData.data.overallData.overallThrPnl);
      setVal3(responseData.data.overallData.overallTv);
      setMaxDrawdown(responseData.data.overallData.overallMdd);
      setCdd(responseData.data.overallData.overallCdd);
      setMdd_date_st(responseData.data.overallData.mddStartDate);
      setMdd_date_end(responseData.data.overallData.mddEndDate);
      setPosDays(responseData.data.overallData.overallPositiveDays);
      setNegDays(responseData.data.overallData.overallNegativeDays);
      setAnnualisedPnl(responseData.data.overallData.overallAnnualisedPnl);
      // setColumns(rowsArr[0]);
      setChartData(chartFormattedData);
    } catch (err) {
      // console.log("overalldata")
      console.log("error happened", err);
      setLoading(false);

      showErrorSnackbar(err.message);
    }
  };

  //Function to fetch the strategy wise data
  const fetchStrategyWiseData = async () => {
    let pieProfitData = [];
    let pieLossData = [];
    let overallLoss = 0;
    let overallProfit = 0;
    let years = [];
    let strategyWise = [];
    if (selectedFormat === "Custom Date") {
      let month1 = parseInt(date.getMonth()) + 1;
      let year1 =
        month1 > 3
          ? parseInt(date.getFullYear())
          : parseInt(date.getFullYear()) - 1;
      let month2 = parseInt(date_2.getMonth()) + 1;
      let year2 =
        month2 > 3
          ? parseInt(date_2.getFullYear())
          : parseInt(date_2.getFullYear()) - 1;
      while (year1 <= year2) {
        years.push(year1.toString());
        year1++;
      }
    }
    const body = {
      years: selectedFormat === "Custom Date" ? years : selectedFinyears,
      strategies: selectedStrats,
      date1:
        selectedFormat === "Custom Date" ? convertToSavedFormat(date) : null,
      date2:
        selectedFormat === "Custom Date" ? convertToSavedFormat(date_2) : null,
    };
    try {
      let responseData = await axios.post(
        "/api/live/getStrategyWiseData",
        body,
        {
          headers,
        }
      );
      responseData.data.strategywiseData.forEach((item) => {
        const category = `${item.strategy}`;
        // if (item.netPnl === "N/A") item.netPnl = 0;
        // overallNetPnl += item.netPnl;
        item.netpnl = parseInt(item.netpnl);
        const obj = {
          category,
          netpnl: item.netpnl,
        };
        strategyWise.push(item);
        // pieData.push(obj);
        if (obj.netpnl !== "N/A" && obj.netpnl >= 0) {
          pieProfitData.push(obj);
          overallProfit += obj.netpnl;
        } else if (obj.netpnl !== "N/A" && obj.netpnl < 0) {
          obj.netpnl = -obj.netpnl;
          pieLossData.push(obj);
          overallLoss += obj.netpnl;
        }
        // item.netPnl = parseFloat((item.netPnl / item.uniqueDates) * 252);
      });
      pieProfitData.length > 0 &&
        pieProfitData.forEach((item) => {
          item.netpnl = (item.netpnl / overallProfit) * 100;
        });
      pieLossData.length > 0 &&
        pieLossData.forEach((item) => {
          item.netpnl = (item.netpnl / overallLoss) * 100;
        });
      setProfitPieData(pieProfitData);
      setLossPieData(pieLossData);
      // console.log('strategy wise', strategyWise)
      setStrategyWiseData(strategyWise);
    } catch (err) {
      // console.log("strategywise")
      console.log("error happened", err);
      setLoading(false);

      showErrorSnackbar(err.message);
      // if (err.response.status === 401 || err.response.status === 403)
      //   navigate("/");
      return;
    }
  };

  //Function to get the table data from backend
  const fetchTableData = async () => {
    setLoading(true);
    setFlag(true);
    setIsLanding(false);

    try {
      if (selectedStrats.length === 0 || selectedFinyears.length === 0) {
        let message = "Please select";
        if (selectedFinyears.length === 0) message = `${message} years`;
        if (selectedStrats.length === 0) message = `${message} strategies`;

        throw new Error(`${message} from dropdowns above!`);
      }
      const response1 = fetchYearStrategySubstrategyWiseData();
      const response2 = fetchOverallData();
      const response3 = fetchStrategyWiseData();
      const response4 = fetchYearStrategyWiseData();
      const [res1, res2, res3, res4] = await Promise.all([
        response1,
        response2,
        response3,
        response4,
      ]);
    } catch (err) {
      console.log("error happened", err);
      setLoading(false);

      showErrorSnackbar(err.message);
    }

    setLoading(false);
  };

  //Function to download the summary i.e the visibie table data
  const downloadSummary = (data) => {
    const summaryData =
      Object.keys(data[0]).join(",") +
      "\n" +
      data.map((obj) => Object.values(obj).join(",")).join("\n");
    // console.log("summary", summaryData)

    const blob = new Blob([summaryData], {
      type: "application/json",
    });

    // Create a link element and trigger a download
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "summary.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  //Function to download the data in the desired format
  const downloadData = async (format, fileName) => {
    try {
      const downloadResponse = await downloadDataApi(
        selectedSubstrats,
        allStrategies,
        selectedStrats,
        isLanding,
        strats,
        selectedFinyears,
        headers,
        format
      );
      const response = downloadResponse.response;

      if (downloadResponse.err) throw downloadResponse.err;

      downloadLocally(fileName, response);
    } catch (err) {
      // emailer(err.message);
      return showErrorSnackbar(err.message);
    }
  };

  useEffect(() => {
    let subStrs = [];
    let selectedSubstrs = [];
    allStrategies.forEach((strategyObj) => {
      if (selectedStrats.includes(strategyObj.name)) {
        subStrs.push(...strategyObj.subStrategy);
        selectedSubstrs.push(...strategyObj.subStrategy);
      }
    });

    // console.log('substr', subStrs);

    if (subStrs.length) {
      subStrs.unshift("Clear All");
      subStrs.unshift("Select All");
    }
    setSubStrategies(subStrs);
    setSelectedSubstrats(selectedSubstrs);
  }, [selectedStrats]);

  useEffect(() => {
    let strategies = [];
    const newValues = ["Select All", "Clear All"];
    selectedClients.forEach((client) => {
      strategies.push(...allClients[client]);
    });
    // console.log("strategy", strategies);
    if (strategies.indexOf("Select All") === -1) {
      setStrats([...newValues, ...strategies]);
    }
    setSelectedStrats(
      selectedStrats.filter((str) => strategies.indexOf(str) !== -1)
    );
  }, [selectedClients]);

  //This useEffect is run only once when the component is loaded for the first time
  useEffect(() => {
    fetchLandingData();

    const newValues = ["Select All", "Clear All"];

    if (years.indexOf("Select All") === -1) {
      setYears([...newValues, ...years]);
      // setSelectedFinyears(["2024"]);
      setSelectedFinyears(["2025"]);
      // setSelectedYears(strats);
    }
  }, []);

  return (
    <div>
      <Header>
        <div
          onClick={() => {
            console.log("Here")
            let pieProfitData = [];
            let pieLossData = [];
            let overallProfit = 0;
            let overallLoss = 0;
            let rowsArr = [];
            let strategies = [];
            let clients = [];
            const newValues = ["Select All", "Clear All"];

            landingData.forEach((item) => {
              const category = `${item.strategy}`;
              // if (item.netPnl === "N/A") item.netPnl = 0;
              // overallNetPnl += item.netPnl;

              item.netpnl = parseInt(item.netpnl);
              const obj = {
                category,
                netpnl: item.netpnl,
              };
              // pieData.push(obj);
              if (obj.netpnl !== "N/A" && obj.netpnl >= 0) {
                pieProfitData.push(obj);
                overallProfit += obj.netpnl;
              } else if (obj.netpnl !== "N/A" && obj.netpnl < 0) {
                obj.netpnl = -obj.netpnl;
                pieLossData.push(obj);
                overallLoss += obj.netpnl;
              }
              // item.netPnl = parseFloat((item.netPnl / item.uniqueDates) * 252);
            });

            strategies.push(...allStrategies.map((item) => item.name));
            clients.push(...Object.keys(allClients));

            pieProfitData.length > 0 &&
              pieProfitData.forEach((item) => {
                item.netpnl = (item.netpnl / overallProfit) * 100;
              });
            pieLossData.length > 0 &&
              pieLossData.forEach((item) => {
                item.netpnl = (item.netpnl / overallLoss) * 100;
              });
            landingData.map((d) => rowsArr.push(Object.keys(d)));

            let chartFormattedData = [];
            setSelectedFormat("Year wise");
            // setSelectedFinyears(["2024"]);
            setSelectedFinyears(["2025"]);
            if (strategies.indexOf("Select All") === -1) {
              setStrats([...newValues, ...strategies]);
              setSelectedStrats(strategies);
            }
            setSelectedClients(clients);
            if (backupOverallData.current) {
              backupOverallData.current.chartData.forEach((item) => {
                const chartObj = {
                  date: convertDate(item.date),
                  netPnl: item.netPnl,
                };
                chartFormattedData.push(chartObj);
              });
              setVal1(backupOverallData.current.overallNetPnl);
              setVal2(backupOverallData.current.overallThrPnl);
              setVal3(backupOverallData.current.overallTv);
              setMaxDrawdown(backupOverallData.current.overallMdd);
              setCdd(backupOverallData.current.overallCdd);
              setMdd_date_st(backupOverallData.current.mddStartDate);
              setMdd_date_end(backupOverallData.current.mddEndDate);
              setPosDays(backupOverallData.current.overallPositiveDays);
              setNegDays(backupOverallData.current.overallNegativeDays);
              setAnnualisedPnl(backupOverallData.current.overallAnnualisedPnl);
            }
            setChartData(chartFormattedData);
            setProfitPieData(pieProfitData);
            setLossPieData(pieLossData);
            setColumns(rowsArr[0]);
            setIsLanding(true);
          }}
        >
          <HoverDropdown
            title={"Home"}
            content={{
              Home: () => {
                navigate("/live_data");
              },
              Market: () => {
                navigate("/market");
              },
              Instances: () => {
                navigate("/instances");
              },
              Allocation: () => {
                navigate("/allocation");
              },
            }}
            icon={false}
          />
        </div>

        <HoverDropdown
          title={"Downloads"}
          content={
            selectedFormat === "Year wise"
              ? {
                  Summary: downloadSummary,
                  "Monthly Report": () =>
                    downloadData("month", "monthlyReport"),
                  "Daily Report": () => downloadData("daily", "dailyReport"),
                  "Raw Report": () => downloadData("raw", "rawReport"),
                }
              : { Summary: downloadSummary }
          }
          data={isLanding ? landingData : tableData}
          icon={true}
        />
      </Header>
      <div class="flex justify-center mt-3">
        <ToggleSwitch
          option1={"live"}
          option2={"backtesting"}
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
        />
      </div>
      {new Date().getTime() > tokenExpiryTime ? (
        <SessionExpiredDialog
          expired={new Date().getTime() > tokenExpiryTime}
        />
      ) : (
        <div>
          <div class="flex w-full my-3 ml-3">
            <div class="w-1/6">
              <DropdownHelper
                selectedVal={selectedFormat}
                setSelectedVal={setSelectedFormat}
                content={formats}
                title={"Select Format"}
              />
            </div>
            {selectedFormat === "Year wise" ? (
              <div class="w-1/6">
                <FilterDropdown
                  selectedVals={selectedFinyears}
                  setSelectedVals={setSelectedFinyears}
                  content={years}
                  title={"Financial Years"}
                />{" "}
              </div>
            ) : (
              <>
                <div class="mx-3 w-fit">
                  <CalendarComp date={date} setDate={setDate} />
                </div>
                <div class="mx-3 w-fit">
                  <CalendarComp date={date_2} setDate={setDate_2} />
                </div>
              </>
            )}

            <div class="w-1/6">
              <FilterDropdown
                selectedVals={selectedClients}
                setSelectedVals={setSelectedClients}
                content={clients}
                title={"Client"}
              />
            </div>
            <div class="w-1/6">
              <FilterDropdown
                selectedVals={selectedStrats}
                setSelectedVals={setSelectedStrats}
                content={strats}
                title={"Strategy"}
              />
            </div>
            {subStrategies.length > 0 ? (
              <div class="w-1/6">
                <FilterDropdown
                  selectedVals={selectedSubstrats}
                  setSelectedVals={setSelectedSubstrats}
                  content={subStrategies}
                  title={"Substrategy"}
                />
              </div>
            ) : null}
          </div>
          <div
            onClick={fetchTableData}
            class="mt-6 flex justify-center items-center bg-cyan-700 rounded mx-auto shadow-lg h-fit hover:scale-110 w-fit cursor-pointer"
          >
            <p class="text-white text-lg font-bold p-3 font-serif">Search</p>
          </div>

          {loading ? (
            <div class="flex justify-center items-center mt-3">
              <LoaderComp />
            </div>
          ) : (
            <>
              {isLanding ? (
                <div class="flex flex-col w-full mx-auto h-fit">
                  {landingData.length ? (
                    <>
                      <div class="flex flex-col mx-auto w-5/6">
                        <DataTable data={landingData} theadData={columns} />

                        <div class="grid grid-cols-5 gap-4 mt-6 sm:grid-grid-cols-1 ">
                          <Block
                            title={"Netpnl"}
                            val={parseFloat(val1).toLocaleString()}
                            color={val1 > 0 ? "text-green-500" : "text-red-500"}
                          />

                          <Block
                            title={"Thrpnl"}
                            val={parseFloat(val2).toLocaleString()}
                            color={val2 > 0 ? "text-green-500" : "text-red-500"}
                          />

                          <Block
                            title={"TV"}
                            val={parseFloat(val3).toLocaleString()}
                            color={val3 > 0 ? "text-green-500" : "text-red-500"}
                          />
                          <Block
                            title={"MDD"}
                            val={parseFloat(maxDrawdown).toLocaleString()}
                            color={"text-red-500"}
                          />
                          <Block
                            title={"MDD Period"}
                            val={
                              maxDrawdown === 0
                                ? "N/A"
                                : convertDate(mdd_date_st) +
                                  "-" +
                                  convertDate(mdd_date_end)
                            }
                            color={"text-black-500"}
                          />
                          <Block
                            title={"CDD"}
                            val={parseFloat(cdd).toLocaleString()}
                            color={"text-red-500"}
                          />
                          <Block
                            title={"CDD days"}
                            val={parseFloat(
                              cdd_days
                            ).toLocaleString()}
                            color={"text-red-500"}
                          />
                          <Block
                            title={"Positve days"}
                            val={posDays}
                            color={"text-green-500"}
                          />
                          <Block
                            title={"Negative days"}
                            val={negDays}
                            color={"text-red-500"}
                          />
                          <Block
                            title={"Annualised Pnl"}
                            val={parseFloat(annualisedPnl).toLocaleString()}
                            color={
                              annualisedPnl > 0
                                ? "text-green-500"
                                : "text-red-500"
                            }
                          />
                        </div>
                      </div>
                      <div class="flex mt-8">
                        {profitPieData.length > 0 ? (
                          <PieChart
                            type={"live"}
                            data={profitPieData}
                            // title={"Strategy wise profit in 2024"}
                            title={"Strategy wise profit in 2025"}
                          />
                        ) : (
                          <></>
                        )}
                        {lossPieData.length > 0 ? (
                          <PieChart
                            type={"live"}
                            data={lossPieData}
                            // title={"Strategy wise loss in 2024"}
                            title={"Strategy wise loss in 2025"}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                      {chartData.length > 0 ? (
                        <div class="mb-9 mx-auto w-5/6">
                          <LineChart
                            data={chartData}
                            yKey={"netPnl"}
                            text={"Netpnl vs Date"}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : null}
                </div>
              ) : (
                <>
                  {tableData.length ? (
                    <div class="mt-6 flex flex-col mx-auto w-5/6">
                      <div class="flex flex-col">
                        <div class={`flex flex-col w-full mx-auto h-fit`}>
                          {selectedFormat === "Custom Date" ? (
                            <DataTable
                              data={tableData.concat(strategyWiseData)}
                              theadData={columns}
                            />
                          ) : (
                            <>
                              <div class="flex justify-end">
                                <div className="mt-2 w-fit">
                                  <Card
                                    title={"Conversion Rate"}
                                    content={["USD->INR = 83", "BRL->INR=16"]}
                                  />
                                </div>
                              </div>
                              <GroupedTable
                                tableData={tableData}
                                yearStrategyWiseMap={yearStrategyWiseMap}
                              />
                            </>
                          )}
                        </div>
                        <div class="grid grid-cols-5 gap-4 mt-6 sm:grid-grid-cols-1 ">
                          <Block
                            title={"Netpnl"}
                            val={parseFloat(val1).toLocaleString()}
                            color={val1 > 0 ? "text-green-500" : "text-red-500"}
                          />

                          <Block
                            title={"Thrpnl"}
                            val={parseFloat(val2).toLocaleString()}
                            color={val2 > 0 ? "text-green-500" : "text-red-500"}
                          />

                          <Block
                            title={"TV"}
                            val={parseFloat(val3).toLocaleString()}
                            color={val3 > 0 ? "text-green-500" : "text-red-500"}
                          />
                          <Block
                            title={"MDD"}
                            val={
                              selectedFormat === "Custom Date" ||
                              checkYearsContinuity(selectedFinyears)
                                ? parseFloat(maxDrawdown).toLocaleString()
                                : "N/A"
                            }
                            color={"text-red-500"}
                          />
                          <Block
                            title={"MDD Period"}
                            val={
                              maxDrawdown === 0 ||
                              (selectedFormat === "Year wise" &&
                                !checkYearsContinuity(selectedFinyears))
                                ? "N/A"
                                : convertDate(mdd_date_st) +
                                  "-" +
                                  convertDate(mdd_date_end)
                            }
                            color={"text-black-500"}
                          />
                          <Block
                            title={"CDD"}
                            val={
                              selectedFormat === "Custom Date" ||
                              checkYearsContinuity(selectedFinyears)
                                ? parseFloat(cdd).toLocaleString()
                                : "N/A"
                            }
                            color={"text-red-500"}
                          />
                          <Block
                            title={"Positve days"}
                            val={posDays}
                            color={"text-green-500"}
                          />
                          <Block
                            title={"Negative days"}
                            val={negDays}
                            color={"text-red-500"}
                          />
                          <Block
                            title={"Annualised Pnl"}
                            val={parseFloat(annualisedPnl).toLocaleString()}
                            color={
                              annualisedPnl > 0
                                ? "text-green-500"
                                : "text-red-500"
                            }
                          />
                          {/* <Block
                            title={"CDD days"}
                            val={
                              selectedFormat === "Year wise" &&
                              !checkYearsContinuity(selectedFinyears)
                                ? "N/A"
                                : parseFloat(
                                  cdd_days
                                  ).toLocaleString()
                            }
                            color={"text-red-500"}
                          /> */}
                        </div>
                        <div class="flex mt-8">
                          {profitPieData.length > 0 ? (
                            <PieChart
                              type={"live"}
                              data={profitPieData}
                              title={"Strategy wise profit"}
                            />
                          ) : (
                            <></>
                          )}
                          {lossPieData.length > 0 ? (
                            <PieChart
                              type={"live"}
                              data={lossPieData}
                              title={"Strategy wise loss"}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                        {chartData.length > 0 ? (
                          <div class="mb-9 mx-auto w-full">
                            <LineChart
                              data={chartData}
                              yKey={"netPnl"}
                              text={"Netpnl vs Date"}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                        {/* {selectedStrats.length === 1 &&
                        subStrategies.length > 0 &&
                        substrategyChartData.length > 0 ? (
                          <div class="mb-9 mx-auto w-full">
                            <MultipleLineChart data={substrategyChartData} text={"Substrategy PNL Charts"}/>
                          </div>
                        ) : (
                          <></>
                        )} */}
                      </div>
                      {/* <div class="my-10">
                    <LineChart data={chartData} />
                  </div>{" "} */}
                    </div>
                  ) : (
                    <>
                      {flag ? (
                        <p class="font-bold text-center mt-6 text-lg">
                          No data exists!{" "}
                        </p>
                      ) : null}
                    </>
                  )}{" "}
                </>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default Dashboard_live;
